import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { ScopeForm } from "./forms/ScopeForm";

import "./styles.scss";
import { CalendarDetailsForm } from "./forms/CalendarDetailsForm";
import { ObjectivesForm } from "./forms/ObjectivesForm";
import { Constraints } from "./constraints/Constraints";
import { CalendarCreationModel } from "../../../utils/interfaces";
import { ClientCategories, ClientData } from "../../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  CalendarOptimizationActionTypes,
  createCalendar,
} from "../../../utils/redux/calendar/calendarOptimizationActions";
import { CalendarCreationActionTypes } from "../../../utils/redux/calendarCreation/calendarCreationActions";
import { useNavigate } from "react-router-dom";
import {
  mapCalendarLevelConstraints,
  mapCalendarLevelForm,
  mapToRequestBody,
} from "../../../utils/mapper";
import { ConstraintsForm } from "./forms/ConstraintsForm";
import { NumberWrapper } from "./constraints/ContraintsStyle";
import {
  ConstraintsManagementActionTypes
} from "Promo/utils/redux/constraintsManagement/constraintsManagementAction";
import { QuestionDialog } from "Promo/components/questionDialog/QuestionDialog";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useGetCategoriesFromRetailer } from "utils/hooks/use.get.categories.from.retailer.hook";

const CalendarCreationSchema = yup.object().shape({
  // quarter: yup.string().required("Select a quarter"),
  // customer: yup.string().required("Select a customer"),
  // category: yup.string().required("Select a category"),
  // name: yup.string().required("A name is required"),
  // referenceCalendar: yup.string(),
  // createRefCalendar: yup.boolean().default(false),
  // maximizationGoals: yup.string().required(),
  // netSales: yup.number().typeError("Net sales must be a number").required(),
  // grossMargin: yup
  //   .number()
  //   .typeError("Gross margin must be a number")
  //   .required(),
  // marketShare: yup
  //   .number()
  //   .typeError("Market share must be a number")
  //   .required(),
  // allowMultibuy: yup.boolean(),
  // allowUpr: yup.boolean(),
  // allowPpr: yup.boolean(),
  // allowNewPrice: yup.boolean(),
  // multibuy: yup.number().default(0),
  // upr: yup.number().default(0),
  // ppr: yup.number().default(0),
  // newPrice: yup.number().default(0),
  // promo_min_per_slot: yup.number(),
  // promo_max_per_slot: yup.number(),
  // priceEstablishment: yup.boolean().default(false),
  // scopeConstraints: yup.array().of(
  //   yup.object().shape({
  //     date_end: yup.date(),
  //     date_start: yup.date(),
  //     slot_name: yup.string(),
  //   })
  // ),
  // promoSpendMin: yup.number(),
  // promoSpendMax: yup.number(),
  // floorProfitMin: yup.number(),
  // floorVolumeMin: yup.number(),
  // floorMarketShareMin: yup.number(),
  // per_product_min: yup.number().default(0),
  // per_product_max: yup.number().default(10),
  // per_subbrand_min: yup.number().default(0),
  // per_subbrand_max: yup.number().default(10),
  // per_visibility_level_min: yup.number().default(0),
  // per_visibility_level_max: yup.number().default(10),
});

type CalCreationProps = {
  selectedMarket: string;
};

export function CalendarCreation(props: CalCreationProps) {
  const { selectedMarket } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clientData: ClientData = useAppSelector(
    (state) => state.reducer.GlobalReducer.clientData
  );
  const formPermissions = useAppSelector(
    (state) => state.reducer.CalendarCreationReducer.forms
  );
  const snapshot = useAppSelector(
    (state) => state.reducer.CalendarCreationReducer.snapshot
  );
  const calendarFormState = useAppSelector(
    (state) => state.reducer.CalendarCreationFormReducer
  );
  const calendarCreationRequest = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.createCalendarRequest
  );
  const refCalendars = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.allRefCalendarsData
  );

  const [constraintSet, setConstraintSet] = useState<any[]>([]);
  const [saveDialog, setSaveDialog] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [constraintId, setConstraintId] = useState<string>("");
  const [resetConstraints, setResetConstraints] = useState<boolean>(false);

  const [confirmOpen, setOpenConfirmModel] = React.useState(false);
  const handleConfirmOpen = () => setOpenConfirmModel(true);
  const handleConfirmClose = () => setOpenConfirmModel(false);

  // Form Variables
  const form = useForm<CalendarCreationModel>({
    mode: "onSubmit",
    resolver: yupResolver(CalendarCreationSchema),
  });
  const {
    formState,
    register,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    watch,
    reset,
    resetField,
  } = form;
  const { errors } = formState;

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     name === "category" && setSelectedCategory(value.category || "");
  //   });
  //   return () => subscription.unsubscribe();
  // });

  const handleResetConstraints = () => {
    setResetConstraints(!resetConstraints);
    dispatch({
      type: ConstraintsManagementActionTypes.CONSTRAINTS_WIPE,
    });
  };

  function takeSnapshot(constraintId) {
    setConstraintId(constraintId);

    const constraintsBody = mapCalendarLevelConstraints(
      calendarFormState,
      formState
    );
    const calendarLevelFormData = mapCalendarLevelForm(getValues());

    const merge = { ...calendarLevelFormData, ...constraintsBody };

    dispatch({
      type: CalendarCreationActionTypes.TAKE_FORM_SNAPSHOT,
      payload: merge,
    });
  }

  function handleFormSubmit(data: CalendarCreationModel) {
    setFormData(data);

    const constraintsBody = mapCalendarLevelConstraints(
      calendarFormState,
      data
    );
    const calendarLevelFormData = mapCalendarLevelForm(getValues());
    const merge = { ...calendarLevelFormData, ...constraintsBody };

    // if (compareObjects(snapshot, merge)) {
    //   saveCalendar(getValues());
    //   return;
    // }
    // setSaveDialog(true);
    onNegative(data);
  }

  function saveCalendar(data) {
    setSaveDialog(false);
    const selectedRetailer = clientData.retailers.filter(
      (v) => v.internal_code === data.customer
    )[0];
    // const selectedBrands: any = [];
    // if (selectedRetailer != null && selectedRetailer.categories !== null)
    //   for (const i in selectedRetailer.categories) {
    //     const category = selectedRetailer.categories[i];
    //     for (const x in category.sub_categories) {
    //       const sub_categories = category.sub_categories[x];
    //       for (const b in sub_categories.brands) {
    //         selectedBrands.push(sub_categories.brands[b]);
    //       }
    //     }
    //   }
    const constraintsBody = mapToRequestBody(calendarFormState, data);

    data["market"] = selectedMarket;
    // data["categories"] = "dummy_category"; // retailers.categories.map((category) => category.internal_code);
    data["constraint_name"] = "";
    data["constraint_id"] = constraintId;
    data["is_template"] = false;

    const merge = { ...data, ...constraintsBody };

    dispatch(createCalendar(merge));
  }

  function onNegative(data) {
    setSaveDialog(false);
    // const selectedRetailer = clientData.retailers.filter((v) => v.internal_code === data.customer)[0];

    // let selectedBrands: any = [];
    // if (selectedRetailer != null && selectedRetailer.categories !== null)
    //   for (let i in selectedRetailer.categories) {
    //     const category = selectedRetailer.categories[i];
    //     for (let x in category.sub_categories) {
    //       const sub_categories = category.sub_categories[x];
    //       for (let b in sub_categories.brands) {
    //         selectedBrands.push(sub_categories.brands[b]);
    //       }
    //     }
    //   }
    const constraintsBody = mapToRequestBody(calendarFormState, data);
    data["constraint_name"] = "";
    data["is_template"] = false;
    data["market"] = selectedMarket;

    const merge = { ...data, ...constraintsBody };
    dispatch(createCalendar(merge));
  }

  const {
    isLoading: getCategoriesIsLoading,
    errorMessage: getCategoriesErrorMessage,
    getCategoriesFromRetailer
  } = useGetCategoriesFromRetailer();
  const [categories, setCategories] = useState<ClientCategories[]>([]);
  const fetchCategories = async (retailerInternalCode: string): Promise<void> => {
    const result = await getCategoriesFromRetailer({
      retailerInternalCode: retailerInternalCode,
      internalGeoCode: selectedMarket
    });
    setCategories(result?.categories ?? [])
  };
  const selectedCustomer = getValues("customer");
  useEffect(() => {
    if (!clientData.retailers.find(x => x.internal_code === selectedCustomer)) {
      return;
    }
    fetchCategories(selectedCustomer);
  }, [selectedCustomer])


  function onPositive(constraints_name, data) {
    setSaveDialog(false);
    //todo please check here how we can grap  the product master data using Haleon structure
    const selectedRetailer = retailers.retailers.filter(
      (v) => v.internal_code === data.customer
    )[0];

    let selectedBrands: any = [];
    if (selectedRetailer != null && selectedRetailer.categories !== null)
      for (let i in selectedRetailer.categories) {
        const category = selectedRetailer.categories[i];
        for (let x in category.sub_categories) {
          const sub_categories = category.sub_categories[x];
          for (let b in sub_categories.brands) {
            selectedBrands.push(sub_categories.brands[b]);
          }
        }
      }

    // const constraintsBody = mapToRequestBody(calendarFormState, data);
    data["constraint_name"] = constraints_name;
    data["is_template"] = true;

    const merge = { ...data /*, ...constraintsBody*/ };
    dispatch(createCalendar(merge));
  }

  useEffect(() => {
    if (calendarCreationRequest.fulfilled) {
      dispatch({
        type: CalendarCreationActionTypes.SET_CONSTRAINTS_AS_INVALID,
      });

      reset();
      navigate("/recommendation/management");

      dispatch({
        type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_RESET,
      });
    }
  }, [calendarCreationRequest]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (!values.quarter || !values.customer) {
        dispatch({
          type: CalendarCreationActionTypes.SET_CONSTRAINTS_AS_INVALID,
        });
      } else {
        dispatch({
          type: CalendarCreationActionTypes.SET_CONSTRAINTS_AS_VALID,
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [dispatch]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "100%",
        overflow: "hidden",
      }}
    >
      <QuestionDialog
        open={saveDialog}
        onClose={() => setSaveDialog(false)}
        onNegative={() => onNegative(formData)}
        onPositive={(data) => {
          onPositive(data, formData);
        }}
      />

      {calendarCreationRequest.pending && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={calendarCreationRequest.pending}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container justifyContent={"space-between"}>
        <Grid>
          <Typography variant="h3">Create Calendar</Typography>
        </Grid>
      </Grid>
      {getCategoriesIsLoading && <Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'left' }}>
          <CircularProgress size={30} sx={{ mr: 1 }} />
          <Typography>Fetching categories...</Typography>
        </Grid>
      </Grid>}
      {getCategoriesErrorMessage && <Alert severity="error" sx={{ mt:1 }}>{getCategoriesErrorMessage}</Alert>}

      <Grid container mt={4} mb={6}>
        <form
          id="calendar_form"
          className="form"
          style={{ width: "100%" }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ typography: "body3" }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              sx={{ typography: "body3" }}
            >
              <Grid
                container
                direction="column"
                tablet={5.75}
                laptop={2.75}
                visibility={!formPermissions?.scope ? "hidden" : "visible"}
              >
                <NumberWrapper>
                  <Typography variant="h4">1</Typography>
                </NumberWrapper>
                <Grid mt={1}>
                  <ScopeForm
                    errors={errors}
                    register={register}
                    retailers={clientData.retailers}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                tablet={5.75}
                laptop={2.75}
                visibility={!formPermissions?.details ? "hidden" : "visible"}
              >
                <NumberWrapper>
                  <Typography variant="h4">2</Typography>
                </NumberWrapper>
                <Grid mt={1}>
                  <CalendarDetailsForm
                    errors={errors}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    refCalendars={refCalendars}
                    selectedMarket={selectedMarket}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                tablet={5.75}
                laptop={2.75}
                visibility={!formPermissions?.objectives ? "hidden" : "visible"}
              >
                <NumberWrapper>
                  <Typography variant="h4">3</Typography>
                </NumberWrapper>
                <Grid mt={1}>
                  <ObjectivesForm errors={errors} register={register} />
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                tablet={5.75}
                laptop={2.75}
                visibility={!formPermissions?.objectives ? "hidden" : "visible"}
              >
                <NumberWrapper>
                  <Typography variant="h4">4</Typography>
                </NumberWrapper>
                <Grid mt={1}>
                  <ConstraintsForm
                    errors={errors}
                    reset={resetConstraints}
                    register={register}
                    takeSnapshot={takeSnapshot}
                    selectedMarket={selectedMarket}
                    selectedCustomer={selectedCustomer}
                  />
                </Grid>
              </Grid>
            </Grid>
            {
              <Grid
                container
                direction="column"
                sx={{ width: "100%" }}
                visibility={
                  !formPermissions?.constraints ? "hidden" : "visible"
                }
              >
                {getValues("referenceCalendar") && (
                  <>
                    <Grid className="section_number" mb={1}>
                      <NumberWrapper>
                        <Typography variant="h4">5</Typography>
                      </NumberWrapper>
                    </Grid>
                    <Constraints
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      watch={watch}
                      reset={resetConstraints}
                      handleReset={handleResetConstraints}
                      retailers={clientData.retailers}
                      selectedCustomer={selectedCustomer}
                      categories={categories}
                    />
                  </>
                )}
              </Grid>
            }
          </Grid>
        </form>
      </Grid>

      <Grid
        sx={{
          width: "100%",
          height: "40px",
          position: "absolute",
          bottom: 0,
          left: 0,
          padding: "2rem 1rem",
          "& button": { padding: ".5rem" },
        }}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap={"1.5rem"}
      >
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.primary.text,
            borderColor: theme.palette.primary.text,
            "&:hover": {
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.text,
            },
          }}
          onClick={handleConfirmOpen}
          disabled={
            getValues("referenceCalendar") === undefined ||
            getValues("referenceCalendar") === "" ||
            getValues("name") === undefined ||
            getValues("name") === ""
          }
        >
          Confirm
        </Button>
      </Grid>
      <Modal open={confirmOpen} onClose={handleConfirmClose}>
        <Box className="confirm_model_style">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Optimization runtime is 1-2 hours.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: theme.palette.primary.text,
              borderColor: theme.palette.primary.text,
              marginRight: "10px",
              "&:hover": {
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.text,
              },
            }}
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="calendar_form"
            variant="outlined"
            sx={{
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.main,
              "&:hover": {
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
              },
            }}
          >
            Continue & Save
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
}
