import { useState } from "react";
import { ClientRetailer } from "utils/types";
import axios from 'axios'

interface UseGetCategoriesFromRetailer {
  isLoading: boolean;
  errorMessage: string;
  getCategoriesFromRetailer: (params: { retailerInternalCode: string; internalGeoCode: string }) => Promise<ClientRetailer | null>
}

export const useGetCategoriesFromRetailer = (): UseGetCategoriesFromRetailer => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getCategoriesFromRetailer = async (params: { retailerInternalCode: string; internalGeoCode: string } ): Promise<ClientRetailer | null> => {
    setIsLoading(true);
    setErrorMessage('');

    let result: ClientRetailer | null = null;
    
    try {
      result = (await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/client/${params.retailerInternalCode.replace(" ", "_")}?internal_geo_code=${params.internalGeoCode}`
      )).data 
    } catch (error) {
      setErrorMessage(
        `There was an error while trying to fetch retailer ${params.retailerInternalCode} with internal_geo_code ${params.internalGeoCode}`
      );
    }
  
    setIsLoading(false);
    return result;
  }

  return {
    isLoading: isLoading,
    errorMessage: errorMessage,
    getCategoriesFromRetailer: getCategoriesFromRetailer
  }
}