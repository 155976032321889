import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Months } from "Promo/utils/constants";
import { CalendarCreationModel } from "Promo/utils/interfaces";
import { useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ClientRetailer } from "utils/types";

const quarters: Array<string> = getQuarters();

function getQuarters() {
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  const quarter_cur = Math.floor((month - 1) / 3);
  let quarters_list: Array<string> = [];
  quarters_list.push(`Full Year ${year}`);
  for (let i = 1; i <= 4; i++) {
    const q = quarter_cur + i;
    if (q > 4) {
      quarters_list.push(`Q${q - 4} ${year + 1}`);
      for (const [key, value] of Object.entries(Months)) {
        if (value === q - 4) quarters_list.push(`${key} ${year + 1}`);
      }
    } else {
      quarters_list.push(`Q${q} ${year}`);
      for (const [key, value] of Object.entries(Months)) {
        if (value === q) quarters_list.push(`${key} ${year}`);
      }
    }
  }

// Additional condition for Q4
  if (quarter_cur === 3) {
    // Add "Q4" and "Full Year" for the next year
    quarters_list.push(`Q4 ${year + 1}`);
    for (const [key, value] of Object.entries(Months)) {
      if (value === 4) quarters_list.push(`${key} ${year + 1}`);
    }
    quarters_list.push(`Full Year ${year + 1}`);
  }

  return quarters_list;
}


interface Props {
  errors: FieldErrors<CalendarCreationModel>;
  register: UseFormRegister<CalendarCreationModel>;
  retailers: ClientRetailer[]
}
export function ScopeForm({ errors, register, retailers }: Props) {
  const [selectedRetailer, setSelectedRetailer] = useState("");

  const quarterField = register("quarter");
  const retailerField = register("customer", {
    onChange: (e) => setSelectedRetailer(e.target.value),
  });
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      className="section_card"
      gap={"1rem"}
      sx={{
        background: theme.palette.primary.background,
      }}
    >
      <Typography variant="h3" mt={1}>
        Scope
      </Typography>
      <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Period*</label>
        </Grid>
        <Grid>
          <Select
            className="input input_large select_input"
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              },
            }}
            {...quarterField}
          >
            {quarters.map((v) => (
              <MenuItem value={v}>
                <Typography align="left" fontSize={14}>
                  {v}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid>
          <div>{errors?.quarter?.message}</div>
        </Grid>
      </Grid>

      <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Retailer*</label>
        </Grid>
        <Grid>
          <Select
            defaultValue={0}
            className="input input_large select_input"
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              },
            }}
            {...retailerField}
          >
            {retailers?.map((ret) => (
              <MenuItem key={ret.internal_code} value={ret.internal_code}>
                <Typography align="left" fontSize={14}>
                  {ret.internal_code}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid>
          <div>{errors?.customer?.message}</div>
        </Grid>
      </Grid>
    </Grid>
  );
}
