import { Box, Button, Grid, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import "./styles.scss";
import { Financials } from "./tabs/Financials";
import { PromoMix } from "./tabs/PromoMix";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { ClientCategories } from "../../../../../utils/types";
import { Promotions } from "./tabs/Promotions";
import { StyledTab } from "./ContraintsStyle";

const tabs = [
  /*"Decision Space", "Scope",*/ "Financials",
  "Timing",
  "Promote/Never Promote Together",
];

// CODE-SMELL Make dynamic based on quarter selected
function generateDefaultScopes() {
  return [
    {
      values: [new Date("01/02/2023"), new Date("01/16/2023")],
      slotName: "C15",
    },
  ];
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const DefaultConstraintUnitValues = {
  // Descision Space
  // "multibuy": 0,
  // "allowMultibuy": true,
  // "upr": 3,
  // "allowUpr": true,
  // "ppr": 0,
  // "allowPpr": true,
  // "np": 0,
  // "allowNp": true,
  // // Scope
  // "allowPriceEstablishment": true,
  // "numPromosPerSlot.min": 2,
  // "numPromosPerSlot.max": 12,
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ px: 5, width: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

type ConstraintsProps = {
  errors: any;
  register: UseFormRegister<CalendarCreationModel>;
  setValue: UseFormSetValue<CalendarCreationModel>;
  getValues: UseFormGetValues<CalendarCreationModel>;
  watch: UseFormWatch<CalendarCreationModel>;
  retailers: ClientData;
  selectedCustomer: string;
  categories: ClientCategories[];
  reset: boolean;
  handleReset: () => void;
};

export function Constraints(props: ConstraintsProps) {
  const {
    errors,
    register,
    setValue,
    getValues,
    watch,
    retailers,
    selectedCustomer,
    categories,
    reset,
    handleReset,
  } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [tabValue, setTabValue] = useState(0);
  const [brands, setBrands] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  const { constraintsCommons, constraintsBrands, constraintsProducts } =
    useAppSelector((state) => {
      return {
        constraintsCommons: state.reducer.ConstraintsManagementReducer.commons,
        constraintsBrands: state.reducer.ConstraintsManagementReducer.brands,
        constraintsProducts:
          state.reducer.ConstraintsManagementReducer.products,
      };
    });

  // Used to import the constraint values from the constraint set to the current form
  useEffect(() => {
    if (constraintsCommons.length >= 1) {
      constraintsCommons.forEach((x) => {
        if (x.name === "allow_multibuy") {
          // setAllowMultibuy(!!x.BINARY);
          setValue("multibuy", x.EQUAL || 0);
          setValue("allowMultibuy", !!x.BINARY);
        }
        if (x.name === "upr") {
          // setAllowUPR(!!x.BINARY);
          setValue("upr", x.EQUAL || 0);
          setValue("allowUpr", !!x.BINARY);
        }
        if (x.name === "ppr") {
          // setAllowPPR(!!x.BINARY);
          setValue("ppr", x.EQUAL || 0);
          setValue("allowPpr", !!x.BINARY);
        }
        if (x.name === "newPrice") {
          // setAllowNewPrice(!!x.BINARY);
          setValue("newPrice", x.EQUAL || 0);
          setValue("allowNewPrice", !!x.BINARY);
        }
      });
    }
  }, [constraintsCommons]);

  // Sets the brands and products based on the selected category in the scope
  useEffect(() => {
    if (retailers && selectedCustomer !== "") {
      let tBrands: any = [];

      categories.forEach((cat: any, index, arr: any) => {
        cat.brands.map((brand) => {
          tBrands.push(brand);
        });
      });

      tBrands.forEach((brand: any, index, arr: any) => {
        let products: any = [];
        brand.products.map((prod) => {
          products.push(prod);
        });
        arr[index].products = [...products];
      });
      setBrands(tBrands);
    } else {
      setBrands([]);
    }
  }, [selectedCustomer]);

  // Set default values for the constraints form, will reset everytime you switch the category
  // TODO should also reset the constraint set import
  useEffect(() => {
    //When the category changes we wipe the constraint values
    setValue("constraints", null);
    if (getValues("customer")) {
      for (const constraint in DefaultConstraintUnitValues) {
        const value = DefaultConstraintUnitValues[constraint];
        setValue(`constraints.${constraint}`, value);
      }
      // TODO also do generation for scopes, should be 'constraints.scopes
    }
  }, [selectedCustomer]);

  useEffect(() => {
    handleReset();
  }, [
    watch("quarter"),
    watch("category"),
    watch("customer"),
    watch("referenceCalendar"),
  ]);

  return (
    <Grid
      container
      direction="column"
      className="section_card"
      gap={"1.6rem"}
      sx={{
        background: theme.palette.primary.background,
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <Typography>Constraints</Typography>
        <Button variant="outlined" color="secondary" onClick={handleReset}>
          Reset
        </Button>
      </Grid>
      
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
        }}
      >
        <Box>
          <Tabs
            value={tabValue}
            orientation="vertical"
            onChange={(e, v) => setTabValue(v)}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: { background: theme.palette.primary.light },
            }}
          >
            {tabs.map((v, i) => (
              <StyledTab key={i} label={v} {...a11yProps(i)} />
            ))}
          </Tabs>
        </Box>
        {/* <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 0 ? "block" : "none",
          }}
        >
          <DecisionSpace
            errors={errors}
            register={register}
            // brands={[...brands]}
            setValue={setValue}
            getValues={getValues}
            // resetField={resetField}
            brands={brands}
            watch={watch}
          />
        </div>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 1 ? "block" : "none",
          }}
        >
          <Scope
            errors={errors}
            register={register}
            setValue={setValue}
            getValues={getValues}
            // control={control}
            watch={watch}
            defaultSlots={generateDefaultScopes()}
            // resetField={resetField}
          />
        </div> */}
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 0 ? "block" : "none",
          }}
        >
          <Financials
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            brands={brands}
            products={[...products]}
            reset={reset}
            onResetComplete={handleReset}
          />
        </div>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 1 ? "block" : "none",
          }}
        >
          <PromoMix
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            brands={brands}
            reset={reset}
            onResetComplete={handleReset}
          />
        </div>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 2 ? "block" : "none",
          }}
        >
          <Promotions
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            brands={brands}
            reset={reset}
            onResetComplete={handleReset}
          />
        </div>
      </Box>
    </Grid>
  );
}
