import { useEffect, useState } from "react";
import PromoKPI from "../../components/promoKPI/PromoKPI";
import PromoSimulationForm, { PROMO_SIMULATION_FORM_SIZE } from "../../components/promoSimulationForm/PromoSimulationForm";
import { Typography, Box, Alert } from "@mui/material";
import { postEventAnalysisPageStyles } from "./eventSimulationPageStyles";
import { PromoEvent } from "./types";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  SimulationInputs,
  ClientData,
  ClientCategories,
} from "../../../utils/types";
import PnLTable from "../../components/consumptionAndRetailerPL/PnLTable";
import {
  SimulationActionTypes,
  fetchAllRefCalendars,
  postEventSimulationAction,
} from "../../utils/redux/simulation/eventSimulationAction";
import LinearProgress from "@mui/material/LinearProgress";
import KPISimulationTable from "./KPISimulationTable";
import AlternativePeriodsTable from "./AlternativePeriodsTable";
import PromoIncrementality from "../../components/promoIncrementality/PromoIncrementality";
import { tacticTypes } from "Promo/components/promoSimulationForm/SelectItemMenues";
import { tooltipItemsToShow } from "Promo/utils/constants";

type SimulationProps = {
  selectedMarket: string;
};

const SimulationPage = (props: SimulationProps) => {
  const { selectedMarket } = props;

  const dispatch = useAppDispatch();

  const classes = postEventAnalysisPageStyles();
  const ClientData: ClientData = useAppSelector(
    (state) => state.reducer.GlobalReducer.clientData
  );
  const {
    eventSimulation,
    pending,
    fulfilled,
    rejected,
    id,
    referenceCalendars,
  }: any = useAppSelector((state) => state.reducer.SimulationReducer);

  const [retailerIndex, setRetailerIndex] = useState<number>(-1);

  const [simulatedEvent, setSimulatedEvent] = useState<PromoEvent | null>(null);
  const [oldFormValue, setOldValues] = useState<SimulationInputs>();
  const [tacticValues, SetTacticValues] = useState<string>("");
  const [refCal, setRefCal] = useState<string>("");

  function deepEqual(x, y) {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x).length === ok(y).length &&
          ok(x).every((key) => deepEqual(x[key], y[key]))
      : x === y;
  }

  useEffect(() => {
    if (ClientData && ClientData.retailers) {
      dispatch({ type: SimulationActionTypes.REF_CALENDARS_RESET });
    }
  }, [ClientData]);

  const handleTacticValues = (values: SimulationInputs) => {
    if (values.tacticTypes == tacticTypes[0]) {
      SetTacticValues(
        values.tacticTypes + ": " + values.singleBuyValue + values.singleBuyPick
      );
    } else if (values.tacticTypes == tacticTypes[1]) {
      SetTacticValues(
        values.tacticTypes +
          ": " +
          values.multiBuyPick1 +
          " " +
          values.multibuyUnit +
          " units, " +
          values.multiBuyPick2 +
          " " +
          values.multibuyAmount +
          values.multiBuyPick3
      );
    }
  };

  function fetchReferenceCalendars(selectedQuarter: string) {
    dispatch(
      fetchAllRefCalendars(selectedQuarter, ClientData.retailers[retailerIndex].internal_code, selectedMarket)
    );
  }

  const onSimulate = (values: SimulationInputs) => {
    if (deepEqual(values, oldFormValue)) return;
    setOldValues(values);
    const formValueToSend = { ...values };
    formValueToSend.product = [formValueToSend.product];
    if (refCal != "") {
      formValueToSend.referenceCalendar = refCal;
    }
    formValueToSend.market = selectedMarket;
    handleTacticValues(values);
    dispatch(postEventSimulationAction({ ...formValueToSend }));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">Predictive Simulation</Typography>
      <PromoSimulationForm
        size={PROMO_SIMULATION_FORM_SIZE.BIG}
        retailersData={ClientData}
        onSubmit={onSimulate}
        setRetailerIndex={setRetailerIndex}
        fetchReferenceCalendars={fetchReferenceCalendars}
        setRefCalendar={setRefCal}
        refCalendars={referenceCalendars?.data}
        refCalendarsLoad={referenceCalendars?.pending}
        refCalendar={refCal}
        market={selectedMarket}
      />
      <br />
      {eventSimulation && (
        <PromoKPI
          KPIs={eventSimulation.kpiIndicator}
          title={"GEPP Key Indicators"}
        />
      )}

      {eventSimulation && (
        <Box
          component={"div"}
          style={{ display: "flex", gap: "22px", marginBottom: "24px" }}
        >
          <Box style={{ width: "50%" }}>
            <PnLTable
              title="GEPP P&L"
              pnLData={eventSimulation.consumptionPL}
              tooltipToShow={tooltipItemsToShow}
            />
          </Box>
          <Box style={{ width: "50%" }}>
            <PnLTable
              title="Retailer P&L"
              pnLData={eventSimulation.retailerPL}
              tooltipToShow={tooltipItemsToShow}
            />
          </Box>
        </Box>
      )}
      {eventSimulation && (
        <PromoIncrementality
          title="Impact Waterfall"
          promoEvent={simulatedEvent}
          chartEvent={eventSimulation?.event}
        />
      )}
      {eventSimulation && (
        <KPISimulationTable
          simulationTable={eventSimulation.simulationTable}
          startDate={eventSimulation.event.date_start}
          endDate={eventSimulation.event.date_end}
          duration={eventSimulation.event.promo_duration}
          visibility={eventSimulation.event.display}
          depth={eventSimulation.event.promo_depth}
          visibilityDefinition={
            eventSimulation.visibilityLevelRetailerDefinition
          }
        />
      )}
      <Box sx={{ mt: "24px" }}>
        {eventSimulation && (
          <AlternativePeriodsTable
            simulationTable={eventSimulation.alternativePeriodsTable}
            tacticValue={tacticValues}
            startDate={eventSimulation.event.date_start}
            endDate={eventSimulation.event.date_end}
            promoDepth={eventSimulation.event.promo_depth}
            duration={eventSimulation.event.promo_duration}
          />
        )}
      </Box>
      {pending && (
        <>
          <Typography variant="h3">Simulation runtime is 2 minutes</Typography>{" "}
          <LinearProgress />
        </>
      )}
      {rejected && (
        <Alert variant="filled" severity="error">
          Error while fetching data
        </Alert>
      )}
    </div>
  );
};

export default SimulationPage;
