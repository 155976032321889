import { Alert, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { CloseButton } from "./CalendarCardStyle";
import { Close } from "@mui/icons-material";
import { useRetreiveLogs } from "utils/hooks/use.retreive.logs.hook";

interface Props {
  open: boolean;
  id: string;
  onClose: () => void;
}
const LogDialog: FunctionComponent<Props> = ({ open, onClose, id }) => {
  const { isLoading, errorMessage, retreiveLogs } = useRetreiveLogs();
  const [logsMap, setLogsMap] = useState<Record<string, string>>({});

  const fetchData = async () => {
    if (!id || !open) {
      return;
    }
    const result = await retreiveLogs({ id: id });
    setLogsMap(result ?? {});
  };

  useEffect(() => {
    fetchData();
  }, [id, open])

  return (
    <Dialog open={open} fullWidth keepMounted={false}>
      <DialogTitle>Logs for job {id}</DialogTitle>
      <CloseButton onClick={onClose} size="small">
        <Close />
      </CloseButton>
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          {isLoading ? 
              (
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={30} />
                </Grid>
              ) :
              Object.keys(logsMap).map(x => (
                <Grid item key={x}>
                  <TextField
                    label={x}
                    rows={10}
                    value={logsMap[x]}
                    multiline
                    fullWidth />
                </Grid>
              ))
          }
          <Grid item sx={{ display: errorMessage ? "block" : "none"}}>
            <Alert severity="error" sx={{ mt:1 }}>{errorMessage}</Alert>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default LogDialog
