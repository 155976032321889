import { useState } from "react";
import axios from 'axios'

interface UseRetreiveLogs {
  isLoading: boolean;
  errorMessage: string;
  retreiveLogs: (params: { id: string; }) => Promise<Record<string, string> | null>;
}

export const useRetreiveLogs = (): UseRetreiveLogs => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const retreiveLogs = async (params: { id: string; }): Promise<Record<string, string> | null> => {
    setIsLoading(true);
    setErrorMessage('');

    let result: Record<string, string> | null = null;

    try {
      result = (
        await axios.get(
          `${process.env.REACT_APP_PROMO_API_URL}/logs/${params.id}`
        )
      ).data
    } catch (error) {
      setErrorMessage(
        `There was an error while trying to retrieve logs of job ${params.id}`
      );
    }

    setIsLoading(false);
    return result;
  };

  return {
    isLoading: isLoading,
    errorMessage: errorMessage,
    retreiveLogs: retreiveLogs
  };
};
